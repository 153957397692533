@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root{
    background-color: var(--primary-color);
    --font-base: "Lato", sans-serif;
    --primary-color: #B6C4B6;
    --secondary-color: #304D30;
    --white-color: white;
    --dark-green-color: #163020;
}

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}