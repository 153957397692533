#work {
    padding-bottom: 6rem;
}

.workText {
    margin: 4rem 8vw 0 8vw;
    font-weight: 300;
    font-size: clamp(1.4em, 2.2vw, 3em);
}

.workFilter {
    flex-wrap: wrap;
    margin: 2rem 0 0 0;
}

.workFilter .workFilterItem {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: 500;
    color: var(--white-color);
    background-color: var(--secondary-color);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin: 0.5rem;
}

.workFilter .workFilterItem:hover {
    color: var(--secondary-color);
    background-color: whitesmoke;
}

.workFilter .item-active {
    color: var(--secondary-color);
    background-color: whitesmoke;
}

.workPortfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

}

.workPortfolio .workItem {
    max-width: 270px;
    flex-direction: column;
    margin: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--dark-green-color);
    color: var(--white-color);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.workPortfolio .workItem:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

.workImg {
    position: relative;
}

.workImg img {
    max-width: 230px;
    min-width: 230px;
    max-height: 230px;
    min-height: 230px;
    border-radius: 0.5rem;
    object-fit: cover;
}

.workHover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;
}

.workHover div {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--white-color);
    margin: 1rem;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
}

.workHover div svg {
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
}

.workContent {
    max-width: 350px;
    padding: 1.2rem 1vw 0.5rem;
    position: relative;
    flex-direction: column;;
}

.workContent .workContentTag {
    position: absolute;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: var(--dark-green-color);
    top: -1.7rem;
}

.workContent .workContentTitle {
    line-height: 1.7;
    font-weight: 600;
    text-align: center;
}

.workContent .workContentText {
    margin-top: 0.5rem;
    text-align: left;
    letter-spacing: 0.5px;
    font-size: clamp(1.1em, 1.3vw, 2.7em);
    font-weight: 300;
    font-family: var(--font-base);
}

/* Media Queries */
@media (min-width: 2000px) {
    .workFilter .workFilterItem {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
    }

    .workPortfolio .workItem {
        max-width: 390px;
        padding: 1.25rem;
        border-radius: 0.75rem;
    }

    .workImg img {
        max-height: 350px;
        max-width: 350px;
    }
}

@media (max-width: 300px) {
    .workPortfolio .workItem {
        margin: 1rem;
    }
}
