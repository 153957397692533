#intro {
    min-height: 100vh;
    background-image: url("../../assets/imageHome.webp");
    background-size: 70vh;
    background-repeat: no-repeat;
    background-position: center bottom;
}

.introContent {
    position: relative;
    top: 38vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.introContent .hanger {
    display: flex;
    justify-content: center;
    align-items: center;
}

.introContent .hanger .locationText {
    background-color: var(--dark-green-color);
    color: var(--white-color);
    max-width: 150px;
    min-height: 95px;
    max-height: 95px;
    padding: 1rem 0 0 2.5rem;
    font-weight: 300;
}

.introContent .hanger .btnLocationWrap {
    background-color: var(--dark-green-color);
    min-height: 95px;
    max-height: 95px;
    min-width: 92px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.introContent .hanger .btnLocationWrap .btnLocation {
    background-color: var(--primary-color);
    padding: 0.6685rem;
    border-radius: 50%;
}

.introContent .hanger .btnLocationWrap .iconLocation {
    transition: all 0.3s ease-in-out;
}

.introContent .hanger .btnLocationWrap .iconLocation:hover {
    fill: var(--dark-green-color);
    cursor: pointer;
}

.introContent .shortIntro {
    position: relative;
    padding-right: 4.2vw;
    font-weight: 300;
    font-size: clamp(1.3em, 2.5vw, 3em);
}

.bigName {
    position: relative;
    top: 48vh;
    font-size: clamp(3.2em, 14vw, 14em);
    font-weight: 500;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    text-align: center;
}

@media (max-width: 900px) {
    .introContent .hanger .locationText {
        padding-left: 1.5rem;
        max-width: 120px;
    }
}

@media (max-width: 700px) {
    .introContent {
        top: 78vh;
        flex-direction: row-reverse;
        max-width: 95%;
    }

    .introContent .hanger .locationText {
        display: none;
    }

    .introContent .shortIntro {
        margin-left: 6vw;
    }

    .introContent .hanger .btnLocationWrap,
    .introContent .hanger .btnLocationWrap .btnLocation {
        background-color: rgba(255, 255, 255, 0);
    }

    .introContent .hanger .btnLocationWrap .iconLocation:hover {
        fill: var(--white-color);
        scale: 1.2;
        cursor: pointer;
    }
}

@media (min-width: 1300px) {
    .introContent .hanger {
        zoom: 1.1;
    }
}

@media (min-width: 1500px) {
    .introContent .hanger {
        zoom: 1.2;
    }
}

@media (min-width: 1700px) {
    .introContent .hanger {
        zoom: 1.3;
    }
}