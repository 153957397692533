#about {
    background-color: var(--secondary-color);
    padding-bottom: 6rem;
}

/* Start the body part */
.bodyAbout {
    display: flex;
}

.bodyAbout .textAbout {
    flex: 1;
    box-sizing: border-box;
    position: relative;
    top: -1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.bodyAbout .textAbout > span {
    flex: 0;
    padding: 2rem 4vw 0 8vw;
}

.bodyAbout .textAbout .slogan {
    font-size: clamp(2em, 3vw, 5em);
    font-weight: bold;
    padding: 4rem 0 0 8vw;
}

.bodyAbout .textAbout .buttonsContainer {
    padding: 4rem 4vw 0 8vw;
    display: flex;
    justify-content: center;
    gap: 7vw;
    min-width: 100%;
}

.bodyAbout .textAbout .buttonsContainer button {
    min-width: clamp(100px, 11vw, 200px);
    min-height: clamp(100px, 11vw, 200px);
    border: none;
    background-color: var(--primary-color);
    color: var(--white-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
    cursor: pointer;
}

.bodyAbout .textAbout .buttonsContainer button span {
    transition: 0.3s ease-in-out;
    font-size: clamp(1.2em, 1.45vw, 2.5em);
    padding-top: 0.5vw;
}

.bodyAbout .textAbout .buttonsContainer button .iconAbout {
    transition: 0.3s ease-in-out;
    font-size: clamp(45px, 5vw, 85px);
}

.bodyAbout .imageAbout {
    flex: 1;
    max-width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 6vw 0 0;
}

.bodyAbout .imageAbout .imgIntro {
    max-width: 90%;
    border-radius: 2%;
}

/* My Skills */
.mySkills {
    display: flex;
    flex-direction: column;
}

.mySkills .textSkills {
    font-size: clamp(2em, 3vw, 5em);
    font-weight: 700;
    margin: 4rem 0 0 8vw;
}

.skillsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem; 
    margin: 0 8vw;
}

.mySkills .skillsItem {
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding-top: 2rem;
}

.mySkills .iconSkill {
    font-size: clamp(38px, 4vw, 90px);
    margin: 0 3vw 0 3vw;
}

.mySkills .skillText {
    font-size: clamp(1.2em, 1.45vw, 3em);
    font-weight: 300;
    margin-top: 1rem; 
}

@media (max-width: 1000px) {
    .bodyAbout .imageAbout {
        max-width: 100%;
        padding: 4rem 4vw 0 4vw;
    }

    .bodyAbout .textAbout > span {
        padding: 2rem 0 0 8vw;
    }

    .bodyAbout .textAbout .slogan {
        font-size: clamp(1.6em, 3vw, 5em);
    }
}

@media (max-width: 700px) {
    .bodyAbout {
        flex-direction: column-reverse;
    }
    

    .bodyAbout .textAbout .slogan {
        font-size: clamp(2em, 3vw, 5em);
    }

    .bodyAbout .textAbout > span {
        flex: 0;
        padding: 2rem 4vw 0 8vw;
    }

    .bodyAbout .textAbout .buttonsContainer {
        padding: 4rem 8vw 0 8vw;
        gap :10vw;
    }
}
