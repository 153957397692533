.navbar {
    max-height: 5rem;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2vw 2rem 3vw;
    padding-top: calc(2.2rem + 12px);
    margin: 0 auto;
    position: fixed;
    z-index: 2;
    font-weight: 400;
    font-size: clamp(18px, 1.3vw, 22px);
    background-color: rgba(182, 196, 182, 0.3); 
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(3px);
}

.homePage {
    cursor: pointer;
    margin-bottom: 12px;
    color: var(--white-color);
}

.homePage:hover {
    color: var(--dark-green-color);
}

.homePage:hover .codeBy {
    transform: translateX(-105%);
}

.homePage:hover .duc {
    transform: translateX(120%);
}

.homePage:hover .huy {
    transform: translateX(-97%);
}

.homePage:hover .ta {
    transform: translateX(-485%);
}

.homePage:hover .logo {
    transform: rotate(180deg) rotateX(180deg) rotateY(180deg);
}

.logoSection {
    display: flex;
    align-items: center;
}

.logoSection .logo {
    max-width: 2.25rem;
    margin-right: 0.7rem;
    transition: 0.5s ease-in-out;
}

.logoSection .name {
    overflow: hidden;
}

.logoSection .name span {
    transition: all 0.3s ease-in-out;
    display: inline-block; 
    position: relative;
}

.logoSection .name .duc {
    right: 70%;
}

.logoSection .name .ta {
    left: 20%;
}

.logoSection .name .huy {
    right:19%
}

.navbarLinks {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.navbarLinks .workClass {
    margin-left: 0.4rem;
}

.navbarLinks li {
    margin: 0 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.navbarLinks li div {
    min-width: 5px;
    min-height: 5px;
    background: transparent;
    border-radius: 50%;
    margin-top: 7px;
    transition: all 0.2s ease-in-out;
}

.navbarLinks li a {
    color: var(--white-color);
    text-decoration: none;
    flex-direction: column;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
}

.navbarLinks li:hover div {
    background: var(--dark-green-color);
}

.navbarLinks li:hover a {
    color: var(--dark-green-color);
}

.smallMenu {
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-green-color);
}

.smallMenu .menuOverlay {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.3); 
    z-index: 4;
}


.smallMenu div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 1.25rem 2.5vw;
    min-width: 40%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    background-color: var(--dark-green-color);
    background-size: cover;
    background-repeat: repeat;
    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
}


.smallMenu div ul {
    list-style: none;
    margin: 0;
    min-height: 97vh;
    min-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.smallMenu div ul li {
    margin: 1.7rem 0 1.7rem 2vw;
    cursor: pointer;
    position: relative;
    padding-top: 2rem;
}

.smallMenu div ul li a {
    color: var(--white-color);
    text-decoration: none;
    font-size: 20px;
    text-transform: capitalize;
    position: relative;
    padding-left: 20px;
}

.smallMenu div ul li a::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    min-width: 5px;
    min-height: 5px;
    background-color: var(--white-color);
    border-radius: 50%;
    opacity: 0;
    transition: 0.3s ease-in-out;
}

.smallMenu div ul li a:hover::before {
    opacity: 1;
}

/* Disable scroll when menu is active */
.no-scroll {
    overflow: hidden;
    height: 100%;
}

.navbarLinks li.active a {
    padding-bottom: 1.5px;
    border-bottom: 1.5px solid var(--white-color);
}

@media (max-width: 700px) {
    .navbarLinks {
        display: none;
    }
}

@media (min-width: 700px) {
    .smallMenu {
        display: none;
    }
}