/* LoadingScreen.css */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: var(--dark-green-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  font-weight: 300;
  z-index: 5;
  transition: transform 1s ease-in-out;
}

.fade-in {
    animation: fadeIn 400ms ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
